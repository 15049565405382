import { mapState } from "vuex";
// 导入api接口
import {
  getOrderListEcpx,
  cancleOrderAll,
  cancleOrderLine,
  addCart,
  exportOrderSap,
  exportOrderEcpx,
  getSelectEcpx,
  getLogisticsEcpx
} from "../api.js";
import ProductItem from "@/components/global/OrderDetail/ProductItem";
import EcpxFiltrate from "./components/EcpxFiltrate/EcpxFiltrate.vue";
import Util from "@/utils/utils";
export default {
  components: {
    ProductItem,
    EcpxFiltrate
  },
  data() {
    return {
      filterLoadFlag: false,
      filList: [],
      wuliuMsg: {
        deliveryExpressNbr: ''
      },
      showWuliu: false,
      visibleTicket: false,
      dataSource: [],
      cancelText: "取消订单", //  取消提示文字
      img: require("@/assets/noticeList/编组 13@3x.png"),
      navList: [], //导航数据
      spinOrderExport: false,
      spinShenExport: false,
      pageLoadFlag: false, //load标志
      creatDate: 1,
      isView: true,
      dateFormat: "YYYY-MM-DD",
      orderList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/order/cis",
          name: "/order",
          title: "电商订单明细",
        },
      ],
      postDate: {
        pageNo: 1,
        pageSize: 10,
        sort: "desc",
      },
      totalPages: "",
      total: 0,
      currentPage: "",
      searchOrderNo: "", //订单号
      visible: false, //是否显示取消订单弹框
      ModalText: "确定取消订单？",
      confirmLoading: false,
      cancleData: { searchStr: "" },
      nolistImg: require("@/assets/order/noList.png"),
      showList: false,
      orderTypeId: "",
      ordertypeCancel: 1,
      shenLoading: false,
      orderLoading: false,
      isFenxiaoSign: "",
      isFenxiaoSignActive: "",
      isChange: false,
      dataArr: [],
      isTrue: false,
      ids: [],
      nums: [],
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      // filList: (state) => state.filtrate.filList,
      isFenxiaoOrder: (state) => state.user.userInfo.account.marketModels,
      account: (state) => state.user.userInfo.account.account,
      //筛选组件加载loadding
      // filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    this.getSelectEcpx()
    // this.getOrderLi()
  },
  methods: {
    clearSelect(){
      console.log('clearSelect', this.filList)
      this.searchOrderNo = ''
      // 先全部清空
      this.filList.forEach(item => {
        item.list.forEach((item2, index2) => {
          item2.isActive = false
          if(index2 === 0){
            item2.isActive = true
          }
        })
      })
      
      this.$refs.filtrate.callBack()

    },
    /**
     * 查询用什么物流
     */
    async getLogisticsEcpx(item) {
      let res = await getLogisticsEcpx({ orderCode: item.orderCode })
      console.log('res1', res)
     if(res.data.code === 0){
      if (res.data.data.serviceCompanyCode) {
        // 跳转到海信发货进度页面
        // this.goToP('/order/progress?id=' + res.data.data.id)
        this.$message.info('暂无发货进度')
      } else {
        // deliveryExpressNbr 也不为空
        if (res.data.data.deliveryExpressNbr) {
          this.showWuliu = true
          this.wuliuMsg = res.data.data
        }
      }
     }else{
      this.$message.info(res.data.msg)
     }

    },
    goToP(path) {
      // this.$router.push(path);
      let routeUrl = this.$router.resolve({
        path: path,
      });
      window.open(routeUrl.href, "_blank");
    },
    async getSelectEcpx() {
      let res = await getSelectEcpx()

      // 订单状态
      let orderStatus = res.data.data.orderStatus
      let statusArr = []
      Object.keys(orderStatus).forEach(key => {
        statusArr.push({
          name: orderStatus[key],
          id: Number(key)
        })
      });

      // 物料组
      let matkls = res.data.data.matkls
      let matklsArr = []
      Object.keys(matkls).forEach(key => {
        matklsArr.push({
          name: matkls[key],
          id: Number(key)
        })
      });

      // 制造数据
      this.filList = [
        {
          title: '订单状态',
          targetId: 'orderStatus',
          list: [
            {
              name: '全部',
              isActive: true
            },
            ...statusArr
          ]
        },
        {
          title: '物料组',
          targetId: 'matklId',
          isShowMoreFlag: false,
          list: [
            {
              name: '全部',
              isActive: true
            },
            ...matklsArr,
          ]
        },
        {
          title: '订单日期',
          targetId: 'rangeTime',
          isShowMoreFlag: false,
          list: [
            {
              name: '全部',
              isActive: true
            },
            {
              name: '本月',
              id: 'month'
            },
            {
              name: '近三月',
              id: 'threeMonth'
            },
            {
              name: '近一年',
              id: 'year'
            },
            {
              type: 'dateGroup'
            },
          ]
        },
      ]

    },
    // 去支付
    handleToPay(item) {
      if (item.orderServiceFeeList && item.orderServiceFeeList.length > 0) {
        let feeCode = item.orderServiceFeeList[0].feeCode;
        this.$router.push({
          path: "/serviceFeeModule/payDetail",
          query: {
            id: feeCode,
            batch: 0,
            b2bOrderCode: item.orderCode,
            orgName: "订单号",
          },
        });
      } else {
        this.$message.warning("暂无待支付订单");
      }
    },
    // 更改搜索数据框
    dataChange(goodsItem, e) {
      goodsItem.checked = true;
      this.$forceUpdate();
    },
    // 多选框更改
    onChange(goodsItem, e, ppIntem) {
      goodsItem.checked = e.target.checked;
      let cherkNum = 0;
      ppIntem.forEach((item) => {
        item.data.forEach((gooItem) => {
          if (gooItem.checked) {
            cherkNum += 1;
          }
        });
      });
      if (cherkNum > 0) {
        this.cancelText = "确认取消";
      } else {
        this.cancelText = "取消订单";
      }
      this.$forceUpdate();
    },
    postSpinTrue(value) {
      this.pageLoadFlag = value;
    },
    handleTab(type) {
      if (this.isFenxiaoSign == "3") {
        this.isFenxiaoSignActive = type;
      }
      this.isDataViewMoreFun();
      if (type == 1) {
        //渠道采购
        // this.getDmsOrderli();
        this.isGetDmsFlag = true;
        this.$router.push({
          path: "/order/dms",
        });
      } else {
      }
    },
    //判断数据是否有选中 赋值class
    isDataViewMoreFun() {
      // isView?'type-wrap-active':''
      let cls = "";
      let count = 0;
      for (let itemL1 of this.filList) {
        for (let itemL2 of itemL1.list) {
          if (itemL2.isActive && itemL2.name != "全部") {
            count++;
          }
        }
      }
      if (count > 0) {
        cls = "fil-checked";
      }
      return cls;
    },
    // 再来一单
    againOrder(id, code) {
      let carts = id;
      let versions = "";
      let orgAndGroup = "";
      let purchaseType = "";
      this.orderList.forEach((item) => {
        if (item.id == id) {
          item.items.forEach((goodsItem) => {
            orgAndGroup = item.orgId + "-" + goodsItem.matklId;
          });
        }
      });
      // versions = versions.substring(0,versions.length-1)
      if (code == "应急订单") {
        purchaseType = "1";
      } else if (code == "常规订单") {
        purchaseType = "2";
      }
      this.$router.push({
        path: "/confirm/orderAgain",
        query: {
          carts: carts,
          versions: versions,
          orgAndGroup: orgAndGroup,
          purchaseType: purchaseType,
        },
      });
    },
    toDetail(proCode, orgId) {
      /**
      this.$router.push({
        path: "/product/detail",
        query: { productCode: proCode, orgId: orgId },
      });
      */
    },
    // 订单导出
    downloadFile(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "采购订单明细.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "采购订单明细.xls");
      }
    },
    // 审核单导出
    downloadFileShen(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "采购订单明细.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "采购订单明细.xls");
      }
    },
    shaiClick() {
      this.isView = !this.isView;
    },
    // 搜索
    onSearchOrderCode() {
      this.postDate.pageNo = 1;
      this.postDate.timeFrame = 1;
      this.postDate.searchKey = this.searchOrderNo;
      this.getOrderLi();
    },

    // 订单导出
    exportOrder() {
      let exportObj = this.postDate;
      delete exportObj.sort;
      this.orderLoading = true;
      exportOrderEcpx(exportObj)
        .then((res) => {
        
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "采购订单明细.xls");
            })
            .finally(() => {
              this.orderLoading = false;
            });
        })
        .catch((error) => {
          this.orderLoading = false;
          console.log("订单导出", error);
        });
    },
    // 审核单导出
    exportOrderSap() {
      let exportObj = this.postDate;
      delete exportObj.sort;
      this.shenLoading = true;
      exportOrderSap(exportObj)
        .then((res) => {
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "审核订单明细.xls");
            })
            .finally(() => {
              this.shenLoading = false;
            });
        })
        .catch((error) => {
          this.shenLoading = false;
          console.log("审核单导出", error);
        });
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = { productId: proid, orgId: orgid, num: 1 };
      addCart(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success("加入购物车成功");
            //更新购物车
            this.$store.dispatch("user/getCartNum");
            this.$store.dispatch("user/getCartInfoList");
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch((error) => {
          this.$message.warning("加入购物车失败");
          console.log("加入购物车", error);
        });
    },
    // 取消行订单
    cancleOrderLine(orderType, statusCode, id) {
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 2,
      };
      this.cancelOrder(obj);
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      this.orderTypeId = item.id;
      this.ordertypeCancel = item.type;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: "取消提醒",
          content:
            "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true;
          },
          onCancel() { },
        });
      } else {
        this.visible = true;
      }
    },
    getReason(value) {
      this.cancelReason = value;
      this.cancleSubmit(this.orderTypeId, this.ordertypeCancel);
    },
    // 取消订单方法
    cancleSubmit(id, type) {
      this.pageLoadFlag = true;
      let obj = {
        id: id,
        cancelReason: this.cancelReason,
        ids: this.ids.join(","),
        nums: this.nums.join(","),
      };
      if (type == 2) {
        cancleOrderLine(obj)
          .then((res) => {
            if (res.data.type == "Y") {
              this.pageLoadFlag = false;
              this.$message.success("订单取消成功");
              this.getOrderLi();
            } else {
              this.pageLoadFlag = false;
              this.$message.warning(res.data.msg, 5);
              this.getOrderLi();
            }
          })
          .catch((error) => {
            this.pageLoadFlag = false;
            console.log(error);
            this.$message.warning("订单取消失败");
          });
      } else {
        cancleOrderAll(obj)
          .then((res) => {
            if (res.data.type == "Y") {
              this.$message.success("订单取消成功");
              this.getOrderLi();
            } else {
              this.pageLoadFlag = false;
              this.$message.warning(res.data.msg, 5);
              this.getOrderLi();
            }
          })
          .catch((error) => {
            this.pageLoadFlag = false;
            console.log(error);
            this.$message.warning("订单取消失败");
          });
      }
    },
    // 判断组合购是否符合 条件
    checkCombinationPurchase(proItem) {
      let standardValArr = []; // 每个组实际要求数量
      let currValArr = []; // 每个组购买总数
      let multipleArr = []; // 每个组购买总数与实际要求数量成比例
      proItem.dataArr.forEach((aaitem) => {
        aaitem.forEach((val) => {
          val.numOrder = 0;
          val.data.forEach((item) => {
            if (item.checked) {
              val.numOrder += item.cherkNum;
            }
          });
          standardValArr.push(val.packageNum);
          currValArr.push(val.numOrder);
          multipleArr.push(val.numOrder % val.packageNum);
        });
      });
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] == 0) {
          return false;
        }
      }
      for (let i = 0; i < multipleArr.length; i++) {
        if (multipleArr[i] != 0) {
          return false;
        }
      }
      let multiple = currValArr[0] / standardValArr[0]; //先生成一个参考比例
      // 判断每组购买总数比例是否与实际要求比例相同
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] / standardValArr[i] != multiple) {
          return false;
        }
      }
      return true;
    },
    cancleOrderAlle(orderType, statusCode, id) {
      //取消整单
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 1,
      };
      this.cancelOrder(obj);
    },
    // 取消整单
    cancleOrderAllezuhe(orderType, statusCode, id, dataItem, cancelText) {
      if (dataItem.cancelText == "取消订单") {
        dataItem.cancelText = "确认取消";
        this.$forceUpdate();
        return;
      }
      this.ids = [];
      this.nums = [];
      // 新的取消逻辑 不允许修改数量取消 只能整单取消
      dataItem.dataArr.forEach((element) => {
        element.forEach((item) => {
          item.data.forEach((aaa) => {
            this.ids.push(aaa.itemId);
            this.nums.push(aaa.cherkNum);
          });
        });
      });
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 1,
        ids: this.ids,
        nums: this.nums,
      };
      this.cancelOrder(obj);
      return;

    },
    handleOk() {
      this.confirmLoading = true;
      cancleOrderAll(this.cancleData)
        .then((res) => {
          if (res.data.type == "Y") {
            this.confirmLoading = false;
            this.getOrderLi();
          } else {
            this.$message(res.data.msg, 5);
            this.getOrderLi();
          }
        })
        .catch((error) => {
          console.log("取消失败", error);
        });
    },
    handleCancel() {
      this.visible = false;
    },
    // 时间排序
    changeFang() {
      if (this.creatDate == 0) {
        this.creatDate = 1;
        this.postDate.sort = "desc";
      } else if (this.creatDate == 1) {
        this.creatDate = 2;
        this.postDate.sort = "asc";
      } else {
        this.creatDate = 0;
        this.postDate.sort = "";
      }
      this.getOrderLi();
    },
    onFilterChange(checkedObj) {
      console.log('onFilterChange')
      //合并入参请求对象
      this.postDate = { ...this.postDate, ...checkedObj[0], pageNo: 1 };

      // 如果rangeTime为空，则要清空starttime，endtime
      if(!this.postDate.rangeTime){
        delete this.postDate.startTime
        delete this.postDate.endTime
      }

      if (!this.postDate.matklId) {
        this.postDate.matklId = null
      }
      if (!this.postDate.orderStatus) {
        this.postDate.orderStatus = null
      }
      // 字符串转数字
      this.postDate.matklId && (this.postDate.matklId = Number(this.postDate.matklId))
      this.postDate.orderStatus && (this.postDate.orderStatus = Number(this.postDate.orderStatus))
      delete this.postDate.filter
      // 转换rangeTime
      if (this.postDate.rangeTime) {
        if (this.postDate.rangeTime === 'month') {
          const now = new Date();
          // 创建一个新的Date对象，月份和年份与当前日期相同，但日期设置为1
          const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          let firstDay = this.formatDate(firstDayOfMonth);
          let today = this.formatDate(new Date());
          this.postDate.startTime = firstDay
          this.postDate.endTime = today
        } else if (this.postDate.rangeTime === 'threeMonth') {
          let {startTime, endTime} = this.getRecentNDaysDateRange(90)
          this.postDate.startTime = startTime
          this.postDate.endTime = endTime
        } else if (this.postDate.rangeTime === 'year') {
          let {startTime, endTime} = this.getRecentNDaysDateRange(365)
          this.postDate.startTime = startTime
          this.postDate.endTime = endTime
        } else {
          let rangeTime = this.postDate.rangeTime.substring(1).split(',')
          this.postDate.startTime = rangeTime[0]
          this.postDate.endTime = rangeTime[1]
        }
      }

      //查询列表
      this.getOrderLi();
    },
    getRecentNDaysDateRange(n) {
      // 确保n是一个正整数
      if (!Number.isInteger(n) || n <= 0) {
        throw new Error('n must be a positive integer');
      }

      const now = new Date();

      // 计算起始日期（当前日期 - n天）
      const startDate = new Date(now);
      startDate.setDate(startDate.getDate() - n + 1); // 加1是因为setDate是基于0的

      // 结束日期就是当前日期
      const endDate = new Date(now);

      // 返回起止时间
      return {
        startTime: this.formatDate(startDate),
        endTime: this.formatDate(endDate)
      };
    },
    // 格式化日期为YYYY-MM-DD
    formatDate(date) {
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的
      let day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    goTo(id, item) {
      let path = "/order/detail_ecpx?id=" + id;
      let routeUrl = this.$router.resolve({
        path: path,
      });
      window.open(routeUrl.href, "_blank");
    },
    getOrderLi() {
      this.pageLoadFlag = true;
      this.showList = false;

      getOrderListEcpx(this.postDate)
        .then((res) => {
          let data = res.data
          if (data) {
            data = data.data
          } else {
            data = {}
          }
        
          this.pageLoadFlag = false;
          console.log('data', data)
          if (data.list && data.list.length > 0) {
            this.orderList = data.list;
           
            this.totalPages = data.totalPages;
            this.total = data.totalCount;
            this.currentPage = data.pageNo;
            this.showList = true;
          } else {
            this.total = data.totalCount;
            this.orderList = [];
            this.showList = true;
          }
        })
        .catch((error) => {
          this.pageLoadFlag = false;
          console.log("列表", error);
        });
    },

    //组合购数据更改
    changeData(items) {
      var map = {},
        dest = [];
      for (var i = 0; i < items.length; i++) {
        var ai = items[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai],
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;
    },
    clickMore() {
      this.pageLoadFlag = true;
      this.postDate.pageNo = this.postDate.pageNo + 1;
      getOrderListEcpx(this.postDate)
        .then((res) => {
          this.orderList = this.orderList.concat(res.data.data.list);
          this.pageLoadFlag = false;
          this.totalPages = res.data.data.totalPages;
          this.total = res.data.data.totalCount;
          this.currentPage = res.data.data.pageNo;
        })
        .catch((error) => {
          console.log("下拉", error);
        });
    },
  },
};
